import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import DashboardLayout from '../layouts/dashboard';

const IndexPage = lazy(() => import('../pages/app'));
const UserPage = lazy(() => import('../pages/user'));
const LoginPage = lazy(() => import('../pages/Login'));
const Page404 = lazy(() => import('../pages/page-not-found'));
const OrgDonorPage = lazy(() => import('../pages/OrgDonor'));
const FindDonorsPage = lazy(() => import('../pages/FindDonors'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <PrivateRoute>
          <DashboardLayout>
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </PrivateRoute>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'user', element: <UserPage /> },
        { path: 'org-donor', element: <OrgDonorPage /> },
        { path: 'find-donors', element: <FindDonorsPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
