import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import logoPath from './logo.svg';
import { RouterLink } from '../../routes/components';

// ---------------------------------------------------------------------- updated logo

const Logo = forwardRef(({ disabledLink = false, width = 143, sx, ...other }, ref) => {
  const logo = (
    <Box
      component="img"
      src={logoPath}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height: 'auto', // Auto height to maintain aspect ratio
        cursor: 'pointer',
        ...sx,
      }}
      {...other}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.displayName = 'Logo';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  width: PropTypes.number,
  sx: PropTypes.object,
};

export default Logo;
