import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    title: 'My Donors',
    path: '/org-donor',
    icon: <LocalHospitalIcon />,
  },
  {
    title: 'find donors',
    path: '/find-donors',
    icon: <SearchIcon />, // Icon for the find donors page
  },
  {
    isDivider: true,
  },
  {
    title: 'user',
    path: '/user',
    icon: <PersonIcon />,
  },
];

export default navConfig;
